// by @terrehbyte (https://github.com/terrehbyte)
// todo - generate colors w/ the following
//        https://tallys.github.io/color-theory/

$color-cta: #ff5e00;
$color-cta-comp: complement($color-cta);

$color-background: #FFFFFF;
$color-footer-mobile-1: darken($color-background, 2%);
$color-footer-mobile-2: darken($color-background, 10%);
$color-background-code: darken($color-background, 2%);
$color-border: #666;
$color-meta: #666;
$color-meta-code: lighten($color-meta, 10%);
$color-link: $color-cta;
$color-link-darkbg: #e2e2e2;
$color-link-lightbg: #383838;
$color-text: #383838;
$color-accent-3: #8c8c8c;
$color-accent-2: #383838;
$color-accent-1: #004E7A;
$color-quote: #004E7A;

// Font Overrides
$font-family-body: "Inter", sans-serif;
$font-family-mono: "JetBrains Mono", sans-serif;
$font-size: 18px;
$line-height: 1.5;

// Layout Overrides
$page-width: 54rem;