#mobile-nav
{
    // default to hide - only show when applicable
    display: none;

    .icon
    {
        position: absolute;
        top: 3.5em;
        right: 2rem;
        display: inline-block;
        font-size: 12.8px;
        text-decoration: none;
        a
        {
            background-image: none;
        }
    }

    #footer
    {
        position: absolute;
    }

    .footer-menu
    {
        ul
        {
            display: none;
        }
    }

    ul.options
    {
        margin: 0;
        padding: 0;
        margin-top: 4em;
        margin-right: 2rem;
        list-style-type: none;
        line-height: 3em;
        text-align: right;
        a
        {
            color: $color-accent-1;
            font-size:1.2rem;
            font-weight: 400;
        }
    }
}