@font-face {
  font-style: normal;
  font-family: "JetBrains Mono";
  font-display: swap;
  src: local("JetBrains Mono"), local("JetBrains-Mono"), url("/lib/JetBrainsMono/web/woff2/JetBrainsMono-Regular.woff2") format("woff2"), url("/lib/JetBrainsMono/web/woff/JetBrainsMono-Regular.woff") format("woff"), url("/lib/JetBrainsMono/web/eot/JetBrainsMono-Regular.eot") format("embedded-opentype"), url("/lib/JetBrainsMono/ttf/JetBrainsMono-Regular.ttf") format("truetype");
};

@font-face {
  font-style: normal;
  font-family: "Inter";
  font-display: swap;
  src: local("Inter"), local("Inter-Regular"), url("/lib/Inter/web/woff2/Inter-Regular.woff2") format("woff2"), url("/lib/Inter/web/woff2/Inter-Regular.woff") format("woff"), url("/lib/Inter/web/woff2/Inter-Regular.otf") format("openType");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-Thin.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-ThinItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-Light.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-LightItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-LightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-Regular.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-Italic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-Medium.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-MediumItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-SemiBold.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-Bold.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-BoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-Black.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("/lib/Inter/web/woff2/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
       url("/lib/Inter/web/woff/Inter-BlackItalic.woff?v=3.19") format("woff");
}
