.post-list {
  padding: 0;

  .post-item {
    margin-bottom: 1rem;
    margin-left: 0;
    list-style-type: none;

    .meta {
      display: block;
      margin-right: 16px;
      min-width: 100px;
      color: $color-meta;
      font-size: 14px;
    }
  }
}
@media (min-width: $mobile-max-width) {
  .post-list {
    .post-item {
      display: flex;
      margin-bottom: 5px;

      .meta {
        text-align: left;
      }
    }
  }
}

.content
{
  .project-list {
    
    list-style: none;
    margin-top: 1.5rem;
    padding-left: 0;

    li
    {
      margin-bottom: 1.5em;
    }
    li:last-child {
      margin-bottom: 0;
    }

    .project-item {
      position: relative;
      width: 100%;
    }

    .project-img
    {
      /* darkn imagen*/
      filter: brightness(50%);
      width: 100%;
    }
    .project-img:hover
    {
      filter: brightness(70%);
    }

    .project-label
    {
      position: absolute;
      bottom: 10%;
      left: .5em;
      width: 80%;

      font-family: "Inter";
      font-weight: bolder;
      font-size: 2em;

      pointer-events: none;

      color: $color-link-darkbg;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .project-at-a-glance
    {
      margin-bottom: 0.2em;
    }

    .project-skill
    {
      background: rgba(0,0,0, 0.1);
      border-radius: 5px;
      padding: 0.2em;
      white-space: nowrap;
    }

    .project-contribution
    {
      font-weight: 300;
    }

    a
    {
      background-image: none;
    }
  }
}

/* mobile overrides */
@media (max-width: $mobile-max-width) {
  .content
    .project-list {
      .project-label {
        font-size: 1.5em;
      }
  }
}

#about, #writing, #projects
{
  > *
  {
    margin-top: 1rem;
  }

  > *:first-child
  {
    margin-top: 0;
  }
}

#writing, #projects
{
  ul
  {    
    padding-left: 0;
  }
}

.body-nav a
{
  font-size: .8em;
  color: #004E7A;
}
